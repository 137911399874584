export default [

    {
        path: '/deplo',
        name: 'deplo',
        component: () => import('./views/DeploHome'),
        children: [
            {
                path: '/deplo',
                name: 'mapitems.overview',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                components: {
                    side_view: () => import(/* webpackChunkName: "deplo" */ './components/MapItemOverviewSidebar')
                },
            },
            {
                path: '/deplo/items/:id',
                name: 'mapitems.detail',
                components: {
                    side_view: () => import(/* webpackChunkName: "deplo" */ './components/MapItemDetailSidebar')
                },
            }]
    }



];
