/* auth.store.js */
import Vue from 'vue'



// State object
const state = {
    /* User */
    authenticated: false,
    user: null,
    hasUpdates: false,
    updates: [],

}

// Getter functions
const getters = {

}

// Actions
const actions = {
    async login({ dispatch }, credentials) {

        await this.$axios.get('/sanctum/csrf-cookie')
        await this.$axios.post('/login', credentials).then(res => {
          if(res.data.twofa) {
            dispatch('me')
            throw '2FA'
          }
      })

        // On first login, acl might not be synced yet.
        // This will be force-reloaded in App.vue (to generate sidebar)

        return dispatch('me')
    },
    passwordRecovery({ dispatch }, credentials) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/password/forgot`, credentials)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    resetPassword({ dispatch }, credentials) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/password/reset`, credentials)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    validateTwofa({ dispatch }, code) {
      return new Promise((resolve, reject) => {
        this.$axios.post(`/2fa`, {'code': code})
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async logout({ dispatch }) {
        await this.$axios.post('/logout')
        return dispatch('me')
    },
    me({ commit, dispatch }) {
        return this.$axios.get('/api/user').then((response) => {
            commit('SET_USER', response.data.data)
            commit('SET_AUTHENTICATED', true)
            dispatch('fetchNotifications', null, {root: true})

        }).catch(() => {
            commit('SET_USER', null)
            commit('SET_AUTHENTICATED', false)
        })
    },
     fetchNotifications({commit}) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/notifications?unread=1`)
                .then((response) => {
                    commit('SET_NOTIFICATIONS', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    markNotificationsAsRead({commit}) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/notifications/read`)
                .then((response) => {
                    commit('SET_NOTIFICATIONS', [])
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

}

// Mutations
const mutations = {
    /* User */
    SET_AUTHENTICATED (state, value) {
        state.authenticated = value
    },

    SET_USER (state, user) {
        state.user = user
        if(user) {
            Vue.prototype.$gates.setPermissions(user.permissions)
            Vue.prototype.$gates.setRoles(user.roles.map(r => r.role))
        }
    },

    SET_NOTIFICATIONS(state, payload) {
        state.updates = payload
        state.hasUpdates = payload.length > 0
    },

}
export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
