export default [
  {
    path: '/anpr',
    name: 'anprs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "anpr" */ './pages/AnprsPage'),
    meta: {
      rule: 'isUser',
    },
  },
  {
    path: '/anpr/:id',
    name: 'anpr',
    component: () => import(/* webpackChunkName: "anpr" */'./pages/AnprPage'),
    meta: {
      rule: 'isUser',
    },
  },
];
