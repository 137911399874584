import axios from 'axios'

export default axios.create({
    baseURL: process.env.VUE_APP_API_URL ?? 'https://portal.towereye.be',
    headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'X-Requested-With': 'XMLHttpRequest'
        // PASTE TOKEN AFTER "Bearer "
        //'Authorization': 'Bearer xxx'
    },
    withCredentials: true
})

