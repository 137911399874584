<template>
  <div class="level-item">
    <div class="pr-3" v-if="status.power_status || status.alarm_status">
      <b-tooltip
        style="max-width: 100%"
        :append-to-body="isNotMobile"
        type="is-dark"
        position="is-top"
        :animated="true"
        multilined
      >
        <div class="has-text-centered">
          <status-indicator
            style="display: inline-block; margin-right: 0.4rem"
            :status="status.online ? 'positive' : 'negative'"
            :pulse="status.online"
          />
          <span
            v-if="expanded"
            v-bind:class="status.online ? 'has-text-grey' : 'has-text-danger'"
            class="text is-size-6 has-text-weight-light">
              {{ status.online ? "online" : "offline" }}
          </span>
        </div>
        <template v-slot:content>
          <p v-if="status.alarm_timestamp">
            <b>Last updated </b>
            {{ status.last_online | moment("from", "now") }} ({{
              status.last_online | moment("Do MMM Y HH:mm:ss")
            }}).
          </p>
        </template>
      </b-tooltip>
    </div>

    <div class="pr-4">
      <b-tooltip
        style="width: 100%"
        :append-to-body="isNotMobile"
        type="is-dark"
        position="is-top"
        :animated="true"
        multilined
      >
        <div class="has-text-centered">
          <b-icon
            :icon="
              powerStatus(status.power_status, status.power_timestamp).indicator
            "
            :type="
              powerStatus(status.power_status, status.power_timestamp).icontype
            "
            size="is-small"
            class="pr-2"
          ></b-icon>
          <span
            v-if="expanded"
            v-bind:class="
              powerStatus(status.power_status, status.power_timestamp).type
            "
            class="text is-size-6 has-text-weight-light"
            >{{
              powerStatus(status.power_status, status.power_timestamp).msg
            }}</span
          >
        </div>
        <template v-slot:content>
          <span v-if="!expanded"
            ><b>Power {{ powerStatus(status.power_status).msg }}</b> | </span
          >
          <span v-if="status.power_lost_at">
            <b>{{ powerStatus(status.power_lost_at).msg }}</b> ({{
              status.power_lost_at | moment("from", "now", true)
            }}) since {{ status.power_lost_at | moment("Do MMM YY HH:mm:ss") }}.
          </span>
          <span v-if="status.power_timestamp">
            <b>Last updated </b>
            {{ status.power_timestamp | moment("from", "now") }} ({{
              status.power_timestamp | moment("Do MMM Y HH:mm:ss")
            }}).
          </span>
        </template>
      </b-tooltip>
    </div>

    <div>
      <b-tooltip
        style="width: 100%"
        :append-to-body="isNotMobile"
        type="is-dark"
        position="is-top"
        :animated="true"
        multilined
      >
        <div class="has-text-centered">
          <b-icon
            :icon="
              alarmStatus(status.alarm_status, status.alarm_timestamp).indicator
            "
            :type="
              alarmStatus(status.alarm_status, status.alarm_timestamp).icontype
            "
            size="is-small"
            class="pr-2"
          ></b-icon>
          <span
            v-if="expanded"
            v-bind:class="
              alarmStatus(status.alarm_status, status.alarm_timestamp).type
            "
            class="text is-size-6 has-text-weight-light"
            >{{
              alarmStatus(status.alarm_status, status.alarm_timestamp).msg
            }}</span
          >
        </div>
        <template v-slot:content>
          <span v-if="!expanded"
            ><b>Alarm {{ alarmStatus(status.alarm_status).msg }} | </b></span
          >
          <span v-if="status.alarm_last_updated_at">
            <b>{{ alarmStatus(status.alarm_status).msg }}</b> ({{
              status.alarm_last_updated_at | moment("from", "now", true)
            }}) since
            {{ status.alarm_last_updated_at | moment("Do MMM YY HH:mm:ss") }}.
          </span>
          <span v-if="status.alarm_timestamp">
            <b>Last updated </b>
            {{ status.alarm_timestamp | moment("from", "now") }} ({{
              status.alarm_timestamp | moment("Do MMM Y HH:mm:ss")
            }}).
          </span>
        </template>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusLineComponent",
  props: {
    status: Object,
    expanded: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isNotMobile() {
      return screen.width > 760;
    },
  },

  methods: {
    powerStatus(status, timestamp) {
      let result = {};
      if (status === "no power") {
        result = {
          type: "has-text-grey",
          indicator: "power-plug-off",
          icontype: "is-danger",
          msg: "no power",
        };
      } else if (status === "partially powered") {
        result = {
          type: "has-text-grey",
          indicator: "power-plug-off",
          icontype: "is-warning",
          msg: "no power",
        };
      } else if (status === "powered") {
        result = {
          type: "has-text-grey",
          indicator: "power-plug",
          icontype: "is-success",
          msg: "powered",
        };
      } else {
        result = {
          type: "has-text-grey",
          indicator: "power-plug",
          icontype: "is-grey",
          msg: "unknown",
        };
      }
      result.problem = false;
      if (!this.status.online) {
        // Long time ago
        result.type = "has-text-grey-light";
        result.icontype = "is-grey-light";
        result.problem = true;
      }
      return result;
    },
    alarmStatus(status, timestamp) {
      let result = {};
      if (status === "triggered") {
        result = {
          type: "has-text-grey",
          indicator: "lock-alert",
          icontype: "is-danger",
          msg: "triggered",
        };
      } else if (status === "armed") {
        result = {
          type: "has-text-grey",
          indicator: "lock",
          icontype: "is-danger",
          msg: "armed",
        };
      } else if (status === "partially armed") {
        result = {
          type: "has-text-grey",
          indicator: "lock-open",
          icontype: "is-warning",
          msg: "partially armed",
        };
      } else if (status === "not armed") {
        result = {
          type: "has-text-grey",
          indicator: "lock-open-variant",
          icontype: "is-success",
          msg: "disarmed",
        };
      } else {
        result = {
          type: "has-text-grey",
          indicator: "lock-question",
          icontype: "is-grey",
          msg: "unknown",
        };
      }
      result.problem = false;
      if (!this.status.online) {
        // Long time ago
        result.type = "has-text-grey-light";
        result.icontype = "is-grey-light";
        result.problem = true;
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
