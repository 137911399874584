<template>
  <aside  class="aside is-placed-right">
    <div ref="notificationside" class="aside-container" @mouseenter="psUpdate">
      <aside-updates @data-updated="psInit"/>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar'
import AsideUpdates from '@/components/AsideUpdates'
import settings from '@/store/modules/settings.store'

export default {
  name: 'AsideRight',
  components: { AsideUpdates },
  computed: {
    ...mapState('settings', [
      'isAsideRightVisible'
    ])
  },
  created () {
    if (!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }
  },
  mounted() {
    this.psInit()
  },
  methods: {
    psInit () {
      this.ps = new PerfectScrollbar(this.$refs.notificationside)
    },
    psUpdate () {
      if (this.ps) {
        this.ps.update()
      }
    }
  }
}
</script>
