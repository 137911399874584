import Vue from 'vue'

export default function ({ next, router }) {
    // If the user is not an admin
    if (!Vue.prototype.$gates.hasRole('super-admin')
        && !Vue.prototype.$gates.hasRole('technician')) {
        return router.push("/403");
    }

    return next()
}