<template>
  <div>
    <div v-if="updates.length <= 0" class="content has-text-grey has-text-centered">
      <br>
      <p> You have no new notifications!</p>
    </div>

    <div v-else>
      <div class="has-text-grey has-text-centered px-4 py-4">
        <b-button @click="markAsRead" icon-left="check" type="is-info is-light" size="is-small" expanded>mark all as read</b-button>
      </div>
      <aside-updates-item v-for="item in updates" :key="item.id" :message="item.data.message" :status="item.data.type"
                          :icon="item.data.icon" :ago="item.updated_at"/>
    </div>
  </div>

</template>

<script>
import AsideUpdatesItem from '@/components/AsideUpdatesItem'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'AsideUpdates',
  components: { AsideUpdatesItem },
  data () {
    return {
      items: []
    }
  },


  computed: {
    ...mapState({
      updates: state => state.auth.updates
    }),
  },

  methods: {
    ...mapActions({
      markAsRead: 'markNotificationsAsRead'
    })
  }
}
</script>
