/* Styles */
import "@/css/indicator.css"

import '@/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'

/* Core */
import Vue from 'vue'
import Vuex from 'vuex'
import Buefy from 'buefy'

/* Router, Store, Axios */
import router from './router'
import store from './store'
import api from './api'

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

/* Vue Gates (Access Contol) */
import VueGates from 'vue-gates';
Vue.use(VueGates, {
  superRole: "super-admin",
  persistent: false
});

/* Vue. Component in recursion */
import AsideMenuList from '@/components/AsideMenuList'

import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
Vue.use(vueFilterPrettyBytes)

/* Mapbox */
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import mapboxvuemarker from 'mapbox-vue-marker'
let plugOptions = {
  mapboxgl
}
Vue.use(mapboxvuemarker, plugOptions)

/* Laravel Echo for Websockets */


import StatusIndicator from 'vue-status-indicator';
Vue.use(StatusIndicator);

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -500,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit('settings/asideMobileStateToggle', false)
})

Vue.config.productionTip = false

/* These components are used in recursion algorithm */
Vue.component('AsideMenuList', AsideMenuList)

Vue.prototype.$axios = api
Vuex.Store.prototype.$axios = api
Vuex.Store.prototype.$gates = Vue.prototype.$gates

// Redirect to Login page once you receive a 401 unauthenticated message (except login itself)
api.interceptors.response.use(null, function (error) {
  if (error.response.status === 401 && !error.response.request.responseURL.endsWith('/api/user')) {
    store.commit('SET_AUTHENTICATED', null)
    router.push('/login')
  }
  return Promise.reject(error)
})

Vue.use(Buefy, {
  defaultDateFormatter: (date) => new Intl.DateTimeFormat(locale, { timezone: "Europe/Brussels" }).format(date)
})

/* Vue Moment (datetime parsing) */
Vue.use(require('vue-moment'));

/* Vue Clipboard */
import VueClipboards from 'vue-clipboards';
Vue.use(VueClipboards);

Vue.config.productionTip = false

/* Vue Cookies */
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)


/* Vue laravel echo*/
import VueEcho from 'vue-echo-laravel';
import pusher from "pusher-js";
// window.Pusher = require('pusher-js');
import Echo from 'laravel-echo';


let authorizer = (channel, options) => {
  return {
    authorize: (socketId, callback) => {
      fetch("http://localhost/lol/", {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify({
          socket_id: socketId,
          channel_name: channel.name
        })
      })
        .then(res => {
          if (!res.ok) {
            throw new Error(`Received ${res.statusCode} from`);
          }
          return res.json();
        })
        .then(data => {
          callback(null, data);
        })
        .catch(err => {
          callback(new Error(`Error calling auth endpoint: ${err}`), {
            auth: ""
          });
        });
    }
  };
};


window.Echo = new Echo({
  broadcaster: 'pusher',
  authEndpoint: '/api/broadcasting/auth',
  auth: {
    headers: { 'x-xsrf-token': Vue.$cookies.get("XSRF-TOKEN") }
  },
  wsHost: window.location.hostname,
  wsPort: 6001,
  key: 'local',
  cluster: 'mt1',
});



// Vue.use(VueEcho, {
//   broadcaster: 'pusher',
//   key: 'local',
//   wsHost: window.location.hostname,
//   wsPort: 6001,
//   wssPort: 443,
//   forceTLS: false,
//   disableStats: true,
//   cluster: 'mt1',
//   enabledTransports: ['ws', 'wss'],
//
// });

store.dispatch('me').then(() => {
   new Vue({
     router,
     store,
     mapboxgl,
     render: h => h(App),
     mounted () {
       document.documentElement.classList.remove('has-spinner-active')
     }
  }).$mount('#app')
})
