<template>
  <div class="is-user-avatar">
    <gravatar  default-img="retro"  :email=newUserAvatar />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Gravatar from 'vue-gravatar';

export default {
  name: 'UserAvatar',
  components: { Gravatar },
  props: {
    email: {
      type: String,
      default: null
    }
  },
  computed: {
    newUserAvatar () {
      if (this.email) {
        return this.email
      }

      if (this.userEmail) {
        return this.userEmail
      }

    },
    ...mapState([
      'userAvatar',
      'userEmail',
    ])
  }
}
</script>
