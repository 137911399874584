<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon"/>
      </a>
<!--      <div class="navbar-item">-->
<!--        <div class="control">-->
<!--          <input class="input" placeholder="Search everywhere...">-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="navbar-brand is-right">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default"/>
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
      <div class="navbar-end">

        <nav-bar-menu v-if="user" class="has-divider has-user-avatar">
          <user-avatar/>
          <div class="is-user-name">
            <span>{{ user.name }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <a class="navbar-item">
              <b-icon icon="account" custom-size="default"></b-icon>
              <span class="has-text-grey-light">My Profile</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="settings" custom-size="default"></b-icon>
              <span class="has-text-grey-light">Settings</span>
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item" @click="logout">
              <b-icon icon="logout" custom-size="default"></b-icon>
              <span>Log Out</span>
            </a>
          </div>
        </nav-bar-menu>
        <a class="navbar-item has-divider is-desktop-icon-only" :class="{'is-active':isAsideRightVisible}" title="Updates" @click.prevent="updatesToggle">
          <b-icon icon="bell" custom-size="default" :class="{'has-update-mark':hasUpdates}" />
          <span>Updates</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import UserAvatar from '@/components/UserAvatar'
import auth from "@/store/modules/auth.store"
import settings from "@/store/modules/settings.store"
import TitleBar from "./TitleBar";


export default {
  name: 'NavBar',
  components: {
    TitleBar,
    UserAvatar,
    NavBarMenu
  },
  data () {
    return {
      isMenuNavBarActive: false
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    ...mapState('settings', [
      'isNavBarVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded',
      'isAsideRightVisible',
      'isDarkModeActive'
    ]),
    ...mapState({
      hasUpdates: state => state.auth.hasUpdates
    }),
    user() {
      return this.$store.state.auth.user;
    }
  },
  created() {
    if(!auth.isRegistered) {
      this.$store.registerModule('auth', auth)
      auth.isRegistered = true
    }
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }
  },
  methods: {
    menuToggle () {
      this.$store.commit('settings/asideStateToggle')
    },
    menuToggleMobile () {
      this.$store.commit('settings/asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    updatesToggle () {
      this.$store.commit('settings/asideRightToggle')
    },
    darkModeToggle () {
      this.$store.commit('settings/darkModeToggle')
    },
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({path: '/login'})
        this.$buefy.snackbar.open({
          message: 'Logged out',
          queue: false
        })
      })
    }
  }
}
</script>
