<template>
  <aside

      v-show="isAsideVisible"
      class="aside is-placed-left is-expanded">
    <aside-tools style="cursor: pointer" @click.native="$router.push('/')" :is-main-menu="true" icon="eye">
      <span slot="label">
        <b>TowerEye</b> Portal
      </span>
    </aside-tools>
    <div ref="menuside" class="menu is-menu-main" @mouseenter="psUpdate">
      <template v-for="(menuGroup, index) in menu" >
        <p class="menu-label" v-if="typeof menuGroup === 'string'" :key="index">{{ menuGroup }}</p>
        <aside-menu-list
            v-else
            :key="index"
            @menu-click="menuClick"
            :menu="menuGroup"/>
      </template>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import AsideTools from '@/components/AsideTools'
import PerfectScrollbar from 'perfect-scrollbar'
import settings from "@/store/modules/settings.store"

export default {
  name: 'AsideMenu',
  components: { AsideTools, PerfectScrollbar },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('settings', [
      'isAsideVisible'
    ])
  },
  mounted() {
    this.psInit()
  },
  methods: {
    menuClick (item) {
      this.$emit('menu-click', item)
    },
    psInit () {
      this.ps = new PerfectScrollbar(this.$refs.menuside)
    },
    psUpdate () {
      if (this.ps) {
        this.ps.update()
      }
    }
  }
}
</script>
