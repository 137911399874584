<template>
  <section class="section is-title-bar">
    <div class="level mb-2">
      <div class="level-left">
        <a @click="$router.go(-1)" class="level-item">
          <b-icon type="is-grey-darker" icon="arrow-left"></b-icon>
        </a>

        <div class="level-item" v-if="titleStack">
          <ul>
            <li v-for="(title, index) in titleStack" :key="index">
              {{ title }}
            </li>
          </ul>
        </div>
        <div v-else class="level-item">
          <ul>
            <li>{{ message }}</li>
          </ul>
        </div>
      </div>
    </div>
    <template v-if="submessage && !titleStack">
      <div class="text pl-5 ml-3">{{ submessage }}</div>
    </template>

    <template v-if="status">
      <div class="level">
        <div class="level-left is-mobile pl-5">
          <StatusLineComponent class="pr-5" :status="status" :heading="false" />
        </div>

        <div class="level-right">
          <div class="buttons level-item">
            <b-button
              class=""
              rounded
              v-scroll-to="{ el: '#scrollto-alerts', offset: -55 }"
              icon-left="alert"
              size="is-small"
            >
              alerts
            </b-button>
            <b-button
              v-if="titleStack.length > 1"
              rounded
              v-scroll-to="{ el: '#scrollto-alarm', offset: -55 }"
              icon-left="lock"
              size="is-small"
            >
              (dis)arm
            </b-button>
            <b-button v-if="titleStack.length > 1"
                      rounded
                      v-scroll-to="{ el: '#scrollto-outputs', offset: -55 }"
                      icon-left="toggle-switch"
                      size="is-small">
              outputs
            </b-button>
            <b-button v-if="titleStack.length"
                      rounded
                      v-scroll-to="{ el: '#scrollto-logs', offset: -55 }"
                      icon-left="notification-clear-all"
                      size="is-small">
              logs
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import StatusLineComponent from './StatusLineComponent';
export default {
  name: 'TitleBar',
  components: { StatusLineComponent },
  props: {
    titleStack: {
      type: Array,
      default: () => [],
    },
    message: {
      type: String,
      default: 'Welcome',
    },
    submessage: {
      type: String,
      default: null,
    },
    status: {
      type: Object,
      default: null,
    },
  },
};
</script>
